<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getUserGroup, addUserGroup, patchUserGroup, severityCode } from "@/services/api/iam.api";
import router from "@/router/index";

const emits = defineEmits(["update-user-group"]);

const props = defineProps({
  groupId: {
    type: String,
    default: undefined,
  },
  addModus: {
    type: Boolean,
    default: false,
  },
});

const editMode = ref(false);
const selectedData = ref({
  id: undefined,
});

const readOnly = computed(() => !props.addModus && !editMode.value);
const disableSave = computed(() => !props.addModus && !selectedData.value.id);

watch(
  () => props.addModus,
  () => {
    console.log("GroupOverview, watch ", props.groupId);
    if (!props.addModus) {
      fetchData();
    }
  },
  {
    immediate: true,
  }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("GroupOverview, fetchData ", props.groupId);
  if (props.groupId && props.groupId !== "") {
    const response = await getUserGroup(props.groupId);
    console.log("GroupOverview, fetchData response ", response.data);

    if (response && !response.error) {
      selectedData.value = response.data;
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
  }
}

async function onEdit() {
  editMode.value = true;
}

async function onCancel() {
  if (props.addModus) {
    router.push({ name: "IAM-UserGroupsOverview" });
  } else {
    editMode.value = false;
    fetchData();
  }
}

const obs = ref(null);

async function onSave() {
  const valid = await obs.value.validate();
  console.log("Group, onSave valid ", valid);
  if (valid) {
    let response = undefined;
    if (props.addModus) {
      response = await addUserGroup(selectedData.value);
      console.log("Group, onSave add ", response);
    } else {
      response = await patchUserGroup(selectedData.value.id, selectedData.value);
      console.log("Group, onSave patch ", response);
    }
    switch (response.severity) {
      case severityCode.hint:
      case severityCode.warning:
        proxy.$toaster.warning(response.message);
        break;
      case severityCode.error:
        proxy.$toaster.error("Bewaren van gebruiker is mislukt! " + response.error);
        break;
    }
    if (response.severity <= severityCode.warning) {
      if (props.addModus) {
        router.push({ name: "IAM-UserGroupsOverview" });
      } else {
        editMode.value = false;
        emits("update-user-group");
      }
    }
  }
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <ValidationObserver ref="obs">
      <v-form>
        <v-container fluid pa-0>
          <v-row>
            <v-col>
              <!-- :class="{noBorders:true}" -->
              <ValidationProvider name="Naam" rules="required" v-slot="{ errors }">
                <v-text-field
                  hide-details="auto"
                  label="Naam"
                  placeholder="naam"
                  persistent-placeholder
                  :outlined="!readOnly"
                  :class="{ noBorders: readOnly }"
                  v-model="selectedData.name"
                  :error-messages="errors"
                  :readonly="readOnly"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details="auto"
                label="Omschrijving"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.description"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid pt-6>
          <v-row>
            <v-col cols="auto" class="form-group" v-if="readOnly">
              <v-btn class="primary" v-on:click="onEdit()" :disabled="disableSave">
                <v-icon dark left>mdi-pencil</v-icon>
                Wijzigen
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-else>
              <v-btn class="primary" v-on:click="onSave()" :disabled="disableSave">
                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                Opslaan
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-show="!readOnly">
              <v-btn v-on:click="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>
